<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <div class="card card-policy-assigned">
            <SingleLineFilter 
                is-first-dates
                is-all-user
                show-first-export
                show-reset
                link-type="report-skye-login-info-export"
                @reset="resetFilters"
            />

            <TableModels 
                :data="store.getters.getLoginInfo(data.data)"
                :data-headers="login_info"
                show-table-total
                :table-total="data.total"
                table-message=""
                :loading="tableLoad"
                :srs="srs"
                show-footer
                :other-data="data"
            />
        </div>
    </div>
</template>

<script setup>
import SingleLineFilter from '@/views/NEW/Reports/Models/SingleLineFilter.vue'
import TableModels from '@/views/NEW/Reports/Models/TableModel.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { dates, rFilters } from '@/store/composable/Reports'
import { paginationsDef } from '@/core/utils/DefaultData'
import moment from 'moment'
import { login_info } from '@/core/utils/Tables'
import { useListing } from '@/store/composable/Listing'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

onMounted(() => {
    resetFilters()
})

const store = useStore()
const route = useRoute()
const loading = ref(false)
const tableLoad = ref(true)
const data = ref(paginationsDef)

const srs = computed(() => {
    return (rFilters.value.page - 1) * rFilters.value.per_page
})

watch(() => rFilters.value, async(filter) => {
    await listing()
}, { deep: true })

async function listing() {
     if(rFilters.value.page) {
        loading.value = true

        window.scrollTo({ top: 0, behavior: 'smooth' })

        const response = await useListing(rFilters.value, route.name)
        loading.value = false
        tableLoad.value = false
        data.value = response.data.data.report
    }
}

function resetFilters() {
    const start = moment().startOf('month').format('YYYY-MM-DD')
    const end = moment().endOf('month').format('YYYY-MM-DD')
    dates.value = [start, end]
    rFilters.value = {
        end_date: end,
        page: 1,
        per_page: 50,
        start_date: start,
        user_id: []
    }

    
}

</script>
